/* The following block can be included in a custom.scss */

/* make the customizations */

$primary-color: #5b4af2;
$theme-colors: (
  'primary': $primary-color,
);

$grid-breakpoints: (
  // xs: 576px,
  xs: 0,
  sm: 800px,
  md: 1025px,
  lg: 1280px,
  xl: 1920px
);

$container-max-widths: (
  xs: 576px,
  sm: 800px,
  md: 1025px,
  lg: 1280px,
  xl: 1920px,
);
$enabled-responsive-font-sizes: true;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
