@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import 'utils/theme/theme';

* {
  font-family: 'Montserrat', sans-serif;

  p,
  table {
    font-size: 14px !important;
    color: #707070;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url(./assets/images/selectChevronDown.svg);
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 22px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #5b4af2 !important;
    font-weight: bold;
    border-color: #ffffff !important;
    border-bottom-color: #e0e0e0 !important;
  }

  .nav-link {
    color: #707070;
    font-size: 0.75rem;
  }

  .btn-primary {
    color: #ffffff !important;
  }

  .btn-light {
    color: #707070 !important;
  }

  .btn {
    font-weight: 700;
    height: 3rem;
    font-size: 0.75rem;
  }

  .btn-outline-primary:hover {
    color: #ffffff;
  }

  .form-label {
    font-size: 0.75rem;
  }

  .form-control {
    height: 48px;
    font-size: 0.75rem;
    color: #707070;
  }

  .form-check-label {
    font-size: 0.75rem;
    color: #707070;
  }

  .form-control::-webkit-input-placeholder {
    font-size: 0.75rem;
    color: #e0e0e0;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f2f2f2 !important;
  }

  b {
    color: #bdbdbd;
  }

  .cursor-pointer:hover {
    cursor: pointer !important;
  }

  th {
    background-color: #fafafa !important;
    color: #707070;
    font-size: 0.75rem;
  }

  td {
    vertical-align: middle !important;
  }

  label {
    font-weight: bolder;
    color: #707070;
  }

  a th,
  h3,
  h4,
  h5 {
    color: #707070;
  }

  strong {
    color: #383838;
  }
}

.superscript {
  position: relative;
  top: -10em;
  font-size: 10%;
}

.item {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}

.notify-badge {
  border-radius: 100%;
  font-size: 12px !important;
  color: #ffffff;
}

.modal-dialog,
.modal-content {
  /* 80% of window height */
  height: auto;
}

input[type='file'] {
  display: none;
}

.dropzone {
  width: 100% !important;
}

:focus {
  outline: none !important;
}

.custom-file-upload {
  outline: none;
  height: 5.75rem;
  width: 100%;
  background: #fafafa;
  /* Shadow */

  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.align-me {
  display: flex;
  align-items: center;
}

@font-face {
  font-family: 'Branding';
  src: local('Branding'),
    url('./fonts/Latinotype\ -\ Branding-Medium.otf') format('truetype');
  font-weight: normal;
}

.image-gallery {
  width: 60% !important;
  height: 60% !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 1px solid #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #f2f2f2 !important;
}

.react-numeric-input > input {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  color: #707070 !important;
  border-radius: 4px !important;
  border-color: rgb(224, 224, 224) !important;
  height: 40px;
}

.react-numeric-input:focus,
.react-numeric-input:active,
.react-numeric-input.active,
.react-numeric-input::after,
.react-numeric-input::before,
.react-numeric-input::after {
  background-color: #fff !important;
  border-color: #f2994a !important;
  /*set the color you want here*/
  outline: none !important;
}

.react-numeric-input > b {
  border-color: #ffffff !important;
  background: #ffffff !important;
}

.react-numeric-input > b > i {
  background: #bdbdbd !important;
}

.breadcrumb-item > a {
  color: #707070;
}

.breadcrumb-item > a {
  color: #707070;
}

.modal-title {
  color: #707070;
  font-weight: 600;
}

.input-group-text {
  background-color: #ffffff;
  border-color: #f2f2f2;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-left-color: #ffffff;
  border-color: #f2f2f2;
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

/**
 * react-date-picker
 */
.react-datepicker-wrapper {
  display: block !important;
}

/*
 * Sweet alert
 */

.swal-button--confirm {
  background-color: #f2994a;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.swal-button--confirm:hover {
  background-color: #ef8526 !important;
  border-color: #ef7e1a !important;
}

.swal-text,
.swal-footer {
  text-align: center !important;
}

.light-scrollbar {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.light-scrollbar::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px #e0e0e0;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.light-scrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.light-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e0e0e0;
}

.highlight-term {
  background-color: yellow;
}

/*
 * Toastify
 */

.Toastify__toast--success {
  background-color: #6fcf97;
}

.Toastify__toast--error {
  background-color: #eb5757;
}

.Toastify__toast--info {
  background-color: #2d9cdb;
}

.Toastify__toast--warning {
  background-color: #f2c94c;
}

.header {
  background-color: #ffffff !important;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
  // padding-top: 10px;
  // padding-bottom: 10px;
}

.avatar-border {
  border: 2px solid #f2994a;
}

.navbar-nav .btn.btn-primary,
.navbar-nav .btn.btn-primary:focus,
.navbar-nav .btn.btn-primary:active {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}
